import Vue from 'vue'
import axios from 'axios'

//axios.defaults.baseURL = 'https://dev.apie-spot.economysoftware.com.br'
axios.defaults.baseURL = (process.env.NODE_ENV == 'development') ? 'http://10.125.121.11:5236' : 'https://apirpa.esmeraldapraiahotel.com.br'


axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//axios.defaults.headers.get['Accept'] = 'application/json'
//axios.defaults.headers.get['Accept'] = 'application/json; charset=utf-8'
//axios.defaults.headers.post['Accept'] = 'application/json; charset=utf-8'

const token = localStorage.getItem('rpatoken')

if (token) {
    axios.defaults.headers.common['x-access-token'] = token
}

Vue.use({
    install(Vue) {
        Vue.prototype.$http = axios
    }
})